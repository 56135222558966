<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">Lineberty</a>
    </div>
    <nb-select class="categoriesSelection" [selected]="currentPage" (selectedChange)="changePage($event)" status="primary">
        <nb-option *ngFor=" let page of availablePages" [value]="page.id"> {{ page.name | lbTranslateFromObject:{lang: locale.language}  }}</nb-option>
    </nb-select>
</div>

<div class="header-container">
    <nb-actions size="small">
        <!--<nb-action class="control-item">
          <nb-search type="rotate-layout"></nb-search>
        </nb-action> -->
        <!-- <nb-action class="control-item" (click)="changeLocale()"> {{ 'header-language' | lbTranslateFromKey:{lang: locale.language}   }}</nb-action>  -->
        <nb-action class="control-item" icon="bell-outline"></nb-action>
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [nbContextMenu]="userMenu"
                     [onlyPicture]="userPictureOnly"
                     [name]="operatorName"
                     [picture]="operator?.personal?.avatar">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
