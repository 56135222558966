import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { UserService } from '../services/user.service'
import { NavigationService } from '../services/utils/navigation.service'
import { CompaniesPlacesQueuesService } from '../services/default-resources/companies-places-queues.service'

@Injectable()
export class LbSecurityRoleGuard  {

    constructor (
        private userService: UserService,
        private navigationService: NavigationService,
        private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
        private router: Router
    ) {
    }

    canActivate ( route: ActivatedRouteSnapshot ) {
        // return true
        let roles = route.data && route.data.roles ? route.data.roles as Array<string> : []
        let allAccessRoles: {ressource: string, availableRoles: string[], queuePlaceOrCompanyId?: string | null}[] | null = route.data && route.data.allAccessRoles ? route.data.allAccessRoles : null
        let hasAccess = this.userService.operatorHasAccessFromObjectAndUrl( roles, allAccessRoles )

        if ( !hasAccess ) {
            this.router.navigate( ['/config/miscellaneous/403'] )
            return false
        } else {
            return true
        }
    }
}
