import { AuthGuard } from './guards/auth-guard.service'
import { CompaniesPlacesQueuesResolver } from './resolvers/companies-places-queues.resolver'
import { NgModule } from '@angular/core'
import { ExtraOptions, RouterModule, Routes } from '@angular/router'
import { LbSecurityRoleGuard } from './guards/lb-security-role.service'
import { NgxSsoRedirectComponent } from './pages/auth/sso-redirect/sso-redirect.component'

const routes: Routes = [
    {
        path: 'developers',
        canActivate: [AuthGuard, LbSecurityRoleGuard],
        data: {roles: ['developer']},
        resolve: {
            companiesPlaceAndQueues: CompaniesPlacesQueuesResolver
        },
        loadChildren: () => import('./pages/developers/developers.module')
            .then( m => m.DevelopersModule )
    },
    {
        path: 'infos',
        canActivate: [AuthGuard, LbSecurityRoleGuard],
        data: {roles: ['all']},
        resolve: {
            companiesPlaceAndQueues: CompaniesPlacesQueuesResolver
        },
        loadChildren: () => import('./pages/infos/infos.module')
            .then( m => m.InfosModule )
    },
    {
        path: 'config',
        canActivate: [AuthGuard, LbSecurityRoleGuard],
        data: {roles: ['all']},
        resolve: {
            companiesPlaceAndQueues: CompaniesPlacesQueuesResolver
        },
        loadChildren: () => import('./pages/config/config.module')
            .then( m => m.ConfigModule )
    },
    {
        path: 'account',
        canActivate: [AuthGuard],
        resolve: {},
        loadChildren: () => import('./pages/account/account.module')
            .then( m => m.AccountModule )
    },
    {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then( m => m.NgxAuthModule )
    },
    { path: 'v1/redirect_sso', component: NgxSsoRedirectComponent },
    { path: '', redirectTo: 'config/settings/queues', pathMatch: 'full' },
    // { path: '', redirectTo: 'infos', pathMatch: 'full' },
    { path: '**', redirectTo: 'auth/login' }
    // { path: '**', redirectTo: 'infos' }
]

const config: ExtraOptions = {
    // enableTracing: true, //DEBUG
    useHash: true
}

@NgModule( {
    imports: [RouterModule.forRoot( routes, config )],
    exports: [RouterModule]
} )
export class AppRoutingModule {
}
