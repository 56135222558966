import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme'

import { LayoutService } from '../../../@core/utils'
import { map, takeUntil } from 'rxjs/operators'
import { capitalize, LbTranslationService } from 'lb-utils-front/dist'
import { Subject } from 'rxjs'
import { NavigationEnd, Router } from '@angular/router'
import { NavigationService } from '../../../services/utils/navigation.service'
import { UserService } from '../../../services/user.service'
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n'

@Component( {
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html'
} )
export class HeaderComponent implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>()
    userPictureOnly: boolean = false
    user: any
    availablePages: any = []

    currentTheme = 'default'
    currentPage = 'developers'
    operator = null
    operatorName = ''

    userMenu = [{ title: 'Profil', url: '/#/account/profile' }, { title: 'Déconnexion', url: '/#/auth/before-logout' }]

    constructor (
          @Inject(L10N_LOCALE) public locale: L10nLocale,
          private sidebarService: NbSidebarService,
          private menuService: NbMenuService,
          private navigationService: NavigationService,
          private themeService: NbThemeService,
          private userService: UserService,
          private location: Location,
          private layoutService: LayoutService,
          private router: Router,
          private breakpointService: NbMediaBreakpointsService,
          private translationService: LbTranslationService,
          private localeService: L10nTranslationService ) {
    }

    async ngOnInit () {
        this.operator = this.userService.getOperatorAccount()

        if ( this.operator && this.operator.personal ) {
            if (this.operator.personal.firstName || this.operator.personal.lastName) {
                this.operatorName = capitalize(this.operator?.personal?.firstName.toLowerCase()) + ' ' + capitalize(this.operator?.personal?.lastName.toLowerCase())
            } else if ( this.operator.personal.surname ) {
                this.operatorName = capitalize(this.operator.personal.surname.toLowerCase())
            } else {
                this.operatorName = '-'
            }
        } else {
            this.operatorName = '-'
        }

        this.currentTheme = this.themeService.currentTheme
        this.currentPage = this.location.path().split('/')[1]
        this.generateListOfPages()

        this.router.events.subscribe(e => {
            // console.log( 'ROUTE EVENT' )
            // console.log( e )
            if (e && e instanceof NavigationEnd) {
                this.currentPage = this.router.url.split('/')[1]
                this.generateListOfPages()
            }
        })

        const { xl } = this.breakpointService.getBreakpointsMap()
        this.themeService.onMediaQueryChange()
            .pipe(
                map( ( [, currentBreakpoint] ) => currentBreakpoint.width < xl ),
                takeUntil( this.destroy$ )
            )
            .subscribe( ( isLessThanXl: boolean ) => this.userPictureOnly = isLessThanXl )

        this.themeService.onThemeChange()
            .pipe(
                map( ( { name } ) => name ),
                takeUntil( this.destroy$ )
            )
            .subscribe( themeName => this.currentTheme = themeName )
    }

    generateListOfPages () {
        const pages = [
            { id: 'developers', security: {roles: ['developer']}, name: { 'fr_FR': 'Gestion des comptes développeurs' } },
            { id: 'config', security: {roles: ['all']}, name: { 'fr_FR': 'Configuration des files' } },
            { id: 'infos', security: {roles: ['admin']}, name: { 'fr_FR': 'Statistiques' } }
        ]

        this.availablePages = []
        for ( const page of pages ) {
            if ( this.userService.operatorHasAccess( page.security.roles ) ) {
                this.availablePages.push( page )
            }
        }

        if ( this.currentPage === 'account' ) {
            this.availablePages.push( { id: 'account', security: {roles: ['all']}, name: { 'fr_FR': 'Profil' } } )
        }
    }

    ngOnDestroy () {
        this.destroy$.next()
        this.destroy$.complete()
    }

    changePage ( page: string ) {
        this.currentPage = page
        this.navigationService.goToLastGlobalPage( this.currentPage )
    }

    toggleSidebar (): boolean {
        this.sidebarService.toggle( true, 'menu-sidebar' )
        this.layoutService.changeLayoutSize()

        return false
    }

    navigateHome () {
        this.menuService.navigateHome()
        return false
    }

    changeLocale() {
        if (this.locale.language === 'fr-FR') {
            this.translationService.setTranslationLang('en_US')
            this.localeService.setLocale({language: 'en-US'})
        }

        else if (this.locale.language === 'en-US') {
            this.translationService.setTranslationLang('fr_FR')
            this.localeService.setLocale({language: 'fr-FR'})
        }
    }
}
