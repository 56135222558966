import { Injectable } from '@angular/core'
import { forkJoin, Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { Operator, OperatorDto } from 'lb-types'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'
import { LbUtilsService } from 'lb-utils-front/dist'
import { CacheService } from '../utils/cache.service'
import { CompaniesPlacesQueuesService } from '../default-resources/companies-places-queues.service'
import { Place, Queue } from 'lb-types/dist'
import { ResourceId } from './developer-account.service'

@Injectable( {
    providedIn: 'root'
} )
export class OperatorsService {

    private operatorsLoaded: boolean = false
    private operators: { [operatorId: string]: OperatorDto } = {}
    private adminAccountsLoaded: boolean = false
    private adminAccounts

    constructor (
        private cacheService: CacheService,
        private companiesPlacesQueuesService: CompaniesPlacesQueuesService,
        private lbUtilsService: LbUtilsService,
        private httpService: HttpService,
        private configService: ConfigService
    ) {
    }

    public getAdminAccounts(): Observable<any> {
        if (this.adminAccountsLoaded) {
            return of(_.cloneDeep(this.adminAccounts))
        } else {
            return this.httpService.get(
                this.configService.httpUrl.operators.getAdminAccounts, null, null
            ).pipe(
                switchMap((res: any) => {
                    this.adminAccountsLoaded = true
                    this.adminAccounts = res
                    return of(_.cloneDeep(this.adminAccounts))
                })
            )
        }
    }

    public getAdminAccountByLoginId( loginId: string ): OperatorDto | null {
        for ( const k in this.adminAccounts) {
            if ( this.adminAccounts[k].loginId === loginId ) {
                return _.cloneDeep(this.adminAccounts[k])
            }
        }

        return null
    }


    public createAdminAccount(accountSet: any): Observable<LocalCRUDRes> {
        return this.httpService.post(
            this.configService.httpUrl.account.createOperatorAccount,
            { account: accountSet }, null, null
        ).pipe(
            switchMap((httpRes: HttpCRUDRes) => {
                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true, res: httpRes.objectSuccess } )
                }
                return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
            })
        )
    }

    public editAdminAccount( params: { operatorId: string, operatorSet: any }[] ): Observable<LocalCRUDRes> {

        let actionHasBeDone = true
        const operators = params.map( obj => this.searchById( obj.operatorId , true) )

        return forkJoin( ...operators ).pipe(
            switchMap( ( foundResTab: { found: boolean, type: string, res: { operator: OperatorDto | null } }[] ) => {
                const set = {}

                for ( const k in foundResTab ) {
                    if ( k in foundResTab ) {
                        if ( foundResTab[k].found ) {
                            const operator = foundResTab[k].res.operator
                            const tmpOperatorSet = params.find( ( obj ) => {
                                return obj.operatorId === operator.operatorId
                            })
                            const operatorSet: any = this.lbUtilsService.diffObject( tmpOperatorSet.operatorSet, operator )

                            if ( operatorSet && Object.keys( operatorSet ).length > 0 ) {
                                if ( typeof (operatorSet.profiles) !== 'undefined' ) {
                                    operatorSet.profiles = _.cloneDeep( tmpOperatorSet.operatorSet.profiles )
                                }
                                set[tmpOperatorSet.operatorId] = operatorSet
                            }
                        }
                    }
                }

                return of( set )
            } ),
            switchMap( ( setParam: any ) => {
                if ( setParam && Object.keys( setParam ).length > 0 ) {
                    return this.httpService.put(
                        this.configService.httpUrl.operators.setAdminAccounts,
                        { operators: setParam }, null, null
                    )
                } else {
                    actionHasBeDone = false
                    return of({
                        success: 0,
                        error: 0,
                        objectSuccess: {},
                        objectError: {}
                    })
                }
            }),
            switchMap( ( httpRes: HttpCRUDRes ) => {
                if ( actionHasBeDone ) {
                    for ( const operatorId in httpRes.objectSuccess ) {
                        if ( operatorId in httpRes.objectSuccess ) {
                            this.operators[operatorId] = httpRes.objectSuccess[operatorId]
                        }
                    }

                    this.deleteOperatorCacheValuesFromOperators( httpRes )

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true, actionHasBeDone: actionHasBeDone } )
                    }
                    else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), actionHasBeDone: actionHasBeDone } )
                    }
                } else {
                    return of( { success: true, actionHasBeDone: actionHasBeDone } )
                }
            })
        )
    }

    public getOperators (): Observable<{ [operatorId: string]: OperatorDto }> {
        if ( this.operatorsLoaded ) {
            return of( _.cloneDeep(this.operators) )
        }
        else {
            return this.httpService.get(
                this.configService.httpUrl.operators.getOperators, null, null
            ).pipe(
                switchMap( ( res: { [operatorId: string]: OperatorDto } ) => {
                    this.operatorsLoaded = true
                    this.operators = res
                    return of( _.cloneDeep( this.operators ) )
                } ),
                first()
            )
        }
    }

    public getOperatorByLoginId( loginId: string ): OperatorDto | null {
        for ( const k in this.operators) {
            if ( this.operators[k].loginId === loginId ) {
                return _.cloneDeep(this.operators[k])
            }
        }

        return null
    }

    public getOperatorById( operatorId: string ): OperatorDto | null {
        if ( this.operators  && this.operators[ operatorId ] ) {
            return _.cloneDeep(this.operators[ operatorId ])
        } else {
            return null
        }
    }

    public getOperatorName ( operator: OperatorDto): string {
        if (
            operator.information && operator.information.personal
            && (
                ( operator.information.personal.firstName && operator.information.personal.firstName.length > 0 )
                || (operator.information.personal.lastName && operator.information.personal.lastName.length > 0 )
            )
        ) {
            let res = ''
            if ( operator.information.personal.firstName && operator.information.personal.firstName.length > 0 ) {
                res += operator.information.personal.firstName
            }
            if ( operator.information.personal.lastName && operator.information.personal.lastName.length > 0 ) {
                res += res.length > 0 ? ' ' : ''
                res += operator.information.personal.lastName
            }
            return res
        } else if ( operator.information && operator.information.personal && operator.information.personal.surname && operator.information.personal.surname.length > 0 ) {
            return operator.information.personal.surname
        } else {
            return '-'
        }
    }

    public searchById ( id: string, isAdmin: boolean = false ): Observable<{ found: boolean, type: string, res: { operator: OperatorDto | null } }> {
        if ( id ) {
            const ob = isAdmin ? this.getAdminAccounts() : this.getOperators()
            return ob.pipe(
                switchMap( ( operators: { [operatorId: string]: OperatorDto } ) => {
                    const operator = operators[id]
                    if ( operator ) {
                        return of( { found: true, type: 'operator', res: { operator: _.cloneDeep( operator ) } } )
                    }
                    else {
                        return of( { found: false, type: '', res: { operator: null } } )
                    }
                } ),
                first()
            )
        }
        else {
            return of( { found: false, type: '', res: { operator: null } } )
        }
    }

    public getOperatorsOfDevAccounts( devAccountsId?: string[]) {
        const res = []
        for ( const operatorId in this.operators) {
            if ( !devAccountsId || devAccountsId.length <= 0 || devAccountsId.indexOf( this.operators[ operatorId ].devAccountId ) >= 0 ) {
                res.push( _.cloneDeep( this.operators[ operatorId ] ) )
            }
        }
        return res
    }

    public getOperatorsFromResourcesId(resourcesId: ResourceId): Observable<any> {
        return this.httpService.get(
            this.configService.httpUrl.operators.getOperatorsFromResourcesId, resourcesId, null
        ).pipe(
            switchMap((res: { [resourceId: string]: { [devAccountId: string]: { [operatorId: string]: OperatorDto } } }) => {
                for (const resourceId in res) {
                    for (const devAccountId in res[resourceId]) {
                        for (const operatorId in res[resourceId][devAccountId]) {
                            if (!this.operators[operatorId]) {
                                this.operators[operatorId] = JSON.parse(JSON.stringify(res[resourceId][devAccountId][operatorId]))
                            }
                        }
                    }
                }
                return of(res)
            })
        )
    }

    public createOperators ( params: Partial<OperatorDto>[] ): Observable<LocalCRUDRes> {

        return this.httpService.post(
            this.configService.httpUrl.operators.createOperators,
            { operators: params }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const operatorId in httpRes.objectSuccess ) {
                    if ( operatorId in httpRes.objectSuccess ) {
                        this.operators[operatorId] = httpRes.objectSuccess[operatorId]
                    }
                }

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true, res: httpRes.objectSuccess } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }

    public deleteOperators ( params: string[] ): Observable<LocalCRUDRes> {

        return this.httpService.delete(
            this.configService.httpUrl.operators.deleteOperators,
            { operatorsIds: params, field: '_id' }, null, null
        ).pipe(
            switchMap( ( httpRes: HttpCRUDRes ) => {
                for ( const operatorId in httpRes.objectSuccess ) {
                    if ( operatorId in httpRes.objectSuccess ) {
                        delete this.operators[operatorId]
                    }
                }

                this.deleteOperatorCacheValuesFromOperators( httpRes )

                if ( httpRes.error === 0 && httpRes.success > 0 ) {
                    return of( { success: true } )
                }
                else {
                    return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), errors: httpRes.objectError } )
                }
            } )
        )
    }

    public editOperators ( params: { operatorId: string, operatorSet: any }[] ): Observable<LocalCRUDRes> {

        let actionHasBeDone = true
        const operators = params.map( obj => this.searchById( obj.operatorId, false ) )

        return forkJoin( ...operators ).pipe(
            switchMap( ( foundResTab: { found: boolean, type: string, res: { operator: OperatorDto | null } }[] ) => {
                const set = {}

                for ( const k in foundResTab ) {
                    if ( k in foundResTab ) {
                        if ( foundResTab[k].found ) {
                            const operator = foundResTab[k].res.operator
                            const tmpOperatorSet = params.find( ( obj ) => {
                                return obj.operatorId === operator.operatorId
                            })
                            const operatorSet: any = this.lbUtilsService.diffObject( tmpOperatorSet.operatorSet, operator )

                            if ( operatorSet && Object.keys( operatorSet ).length > 0 ) {
                                if ( typeof (operatorSet.profiles) !== 'undefined' ) {
                                    operatorSet.profiles = _.cloneDeep( tmpOperatorSet.operatorSet.profiles )
                                }
                                set[tmpOperatorSet.operatorId] = operatorSet
                            }
                        }
                    }
                }

                return of( set )
            } ),
            switchMap( ( setParam: any ) => {
                if ( setParam && Object.keys( setParam ).length > 0 ) {
                    return this.httpService.put(
                        this.configService.httpUrl.operators.setOperators,
                        { operators: setParam }, null, null
                    )
                } else {
                    actionHasBeDone = false
                    return of({
                        success: 0,
                        error: 0,
                        objectSuccess: {},
                        objectError: {}
                    })
                }
            }),
            switchMap( ( httpRes: HttpCRUDRes ) => {
                if ( actionHasBeDone ) {
                    for ( const operatorId in httpRes.objectSuccess ) {
                        if ( operatorId in httpRes.objectSuccess ) {
                            this.operators[operatorId] = httpRes.objectSuccess[operatorId]
                        }
                    }

                    this.deleteOperatorCacheValuesFromOperators( httpRes )

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true, actionHasBeDone: actionHasBeDone } )
                    }
                    else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success), actionHasBeDone: actionHasBeDone } )
                    }
                } else {
                    return of( { success: true, actionHasBeDone: actionHasBeDone } )
                }
            })
        )
    }

    private deleteOperatorCacheValuesFromOperators ( httpRes: HttpCRUDRes ): void {
        const operatorIds = []
        for ( const operatorId in httpRes.objectSuccess ) {
            operatorIds.push( operatorId )
        }
        this.deleteOperatorCacheValues( operatorIds )
    }

    public deleteOperatorCacheValuesFromProfiles( profilesIds: string[] ): void {
        this.getOperatorsIdOfProfiles( profilesIds ).subscribe( ( operatorIds: string[] ) => {
            this.deleteOperatorCacheValues( operatorIds )
        })
    }

    /**
     * Get the list of profilesId of some operators
     * @param operatorIds - the list of operators to search for their profiles
     */
    public getProfilesIdOfOperators( operatorIds: string[] ): string[] {
        const profilesIds: string[] = []
        for ( const operatorId of operatorIds ) {
            const operator: Operator = this.getOperatorById( operatorId )
            const newProfiles: string[] = this.extractProfilesIdFromOperatorProfileObject( operator.profiles )
            profilesIds.push( ...newProfiles )
        }
        return profilesIds
    }

    /**
     * Get the list of profilesId of some operators
     * @param operatorIds - the list of operators to search for their profiles
     */
    public extractProfilesIdFromOperatorProfileObject( profiles: any ): string[] {
        const profilesIds: string[] = []
        if ( profiles ) {
            if ( profiles.all && profiles.all.length > 0 ) {
                for ( const profileId of profiles.all ) {
                    if ( profilesIds.indexOf( profileId ) < 0 ) {
                        profilesIds.push( profileId )
                    }
                }
            }
            if ( profiles.byCompanies && profiles.byCompanies.length > 0 ) {
                for ( const companyData of profiles.byCompanies ) {
                    if ( companyData.all && companyData.all.length > 0 ) {
                        for ( const profileId of companyData.all ) {
                            if ( profilesIds.indexOf( profileId ) < 0 ) {
                                profilesIds.push( profileId )
                            }
                        }
                    }
                    if ( companyData.byPlaces && companyData.byPlaces.length > 0 ) {
                        for ( const placeData of companyData.byPlaces ) {
                            if ( placeData.all && placeData.all.length > 0 ) {
                                for ( const profileId of placeData.all ) {
                                    if ( profilesIds.indexOf( profileId ) < 0 ) {
                                        profilesIds.push( profileId )
                                    }
                                }
                            }
                            if ( placeData.byQueues && placeData.byQueues.length > 0 ) {
                                for ( const queueData of placeData.byQueues ) {
                                    for ( const profileId of queueData.profiles ) {
                                        if ( profilesIds.indexOf( profileId ) < 0 ) {
                                            profilesIds.push( profileId )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return profilesIds
    }

    /**
     * Get the list of operatorId that used a list of profiles
     * @param profilesIds - the list of profile to search
     */
    public getOperatorsIdOfProfiles( profilesIds: string[] ): Observable<string[]> {
        const operatorIds: string[] = []
        return this.getOperators().pipe(
            switchMap( ( operators: { [operatorId: string]: OperatorDto } ): Observable<string[]> => {
                for ( const operatorId in operators ) {
                    if ( operators[operatorId].profiles ) {
                        if ( operators[operatorId].profiles.all && operators[operatorId].profiles.all.length > 0 ) {
                            for ( const profileId of operators[operatorId].profiles.all ) {
                                if ( profilesIds.indexOf( profileId ) >= 0 && operatorIds.indexOf( operatorId ) < 0 ) {
                                    operatorIds.push( operatorId )
                                    break
                                }
                            }
                        }
                        if ( operators[operatorId].profiles.byCompanies && operators[operatorId].profiles.byCompanies.length > 0 ) {
                            for ( const companyData of operators[operatorId].profiles.byCompanies ) {
                                if ( companyData.all && companyData.all.length > 0 ) {
                                    for ( const profileId of companyData.all ) {
                                        if ( profilesIds.indexOf( profileId ) >= 0 && operatorIds.indexOf( operatorId ) < 0 ) {
                                            operatorIds.push( operatorId )
                                            break
                                        }
                                    }
                                }
                                if ( companyData.byPlaces && companyData.byPlaces.length > 0 ) {
                                    for ( const placeData of companyData.byPlaces ) {
                                        if ( placeData.all && placeData.all.length > 0 ) {
                                            for ( const profileId of placeData.all ) {
                                                if ( profilesIds.indexOf( profileId ) >= 0 && operatorIds.indexOf( operatorId ) < 0 ) {
                                                    operatorIds.push( operatorId )
                                                    break
                                                }
                                            }
                                        }
                                        if ( placeData.byQueues && placeData.byQueues.length > 0 ) {
                                            for ( const queueData of placeData.byQueues ) {
                                                for ( const profileId of queueData.profiles ) {
                                                    if ( profilesIds.indexOf( profileId ) >= 0 && operatorIds.indexOf( operatorId ) < 0 ) {
                                                        operatorIds.push( operatorId )
                                                        break
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return of( operatorIds )
            })
        )
    }

    public getOperatorsThatGetQueueAccess( queuesId: string[], devAccountsId?: string[]) {
        const res = []
        const listOfCompanyId = []
        const listOfPlaceId = []
        const listOfQueueId = []
        for ( const queueId of queuesId ) {
            const queue: Queue = this.companiesPlacesQueuesService.getQueueById( queueId )
            if ( queue && listOfQueueId.indexOf( queueId ) < 0 ) {
                listOfQueueId.push( queueId )
                const place: Place = this.companiesPlacesQueuesService.getPlaceById( queue.placeId )
                if ( place && listOfPlaceId.indexOf( queue.placeId ) < 0 ) {
                    listOfPlaceId.push( queue.placeId )
                    if ( place.companyId && listOfCompanyId.indexOf( place.companyId ) < 0 ) {
                        listOfCompanyId.push( place.companyId )
                    }
                }
            }
        }
        const operators: Operator[] = this.getOperatorsOfDevAccounts(devAccountsId)
        for ( const operator of operators) {
            let found = false
            if ( operator.profiles ) {
                if ( operator.profiles.all && operator.profiles.all.length > 0 ) {
                    found = true
                    res.push( operator )
                    continue
                } else if ( operator.profiles.byCompanies && operator.profiles.byCompanies.length > 0 ) {
                    for ( const companyData of operator.profiles.byCompanies ) {
                        if ( listOfCompanyId.indexOf( companyData.companyId ) >= 0 ) {
                            if ( companyData.all && companyData.all.length > 0 ) {
                                found = true
                                res.push( operator )
                                break
                            } else if ( companyData.byPlaces && companyData.byPlaces.length > 0 ) {
                                for ( const placeData of companyData.byPlaces ) {
                                    if ( listOfPlaceId.indexOf( placeData.placeId ) >= 0 ) {
                                        if ( placeData.all && placeData.all.length > 0 ) {
                                            found = true
                                            res.push( operator )
                                            break
                                        } else if ( placeData.byQueues && placeData.byQueues.length > 0 ) {
                                            for ( const queueData of placeData.byQueues ) {
                                                if ( listOfQueueId.indexOf( queueData.queueId ) >= 0 ) {
                                                    found = true
                                                    res.push( operator )
                                                    break
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return res
    }

    public deleteOperatorCacheValues( operatorIds: string[] ): void {
        if ( operatorIds.length > 0 ) {
            const cacheKeys = []
            for ( const operatorId of operatorIds ) {
                cacheKeys.push('/operator/' + operatorId + '/roles')
            }

            this.cacheService.deleteKeys( cacheKeys, true ).subscribe( () => {
                console.log('Operator rôles cache key deleted')
                console.log( cacheKeys )
            }, ( err ) => {
                console.log('Error while deleting operator rôles cache keys')
                console.log(cacheKeys)
                console.log(err)
            })
        }
    }
}
