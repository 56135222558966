import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { first, switchMap } from 'rxjs/operators'
import * as _ from 'lodash'
import { HttpService } from '../utils/http.service'
import { ConfigService } from '../config.service'
import { Company, Langs, Place, Queue } from 'lb-types'
import { CompaniesPlacesAndQueues, CompaniesPlacesAndQueuesHttpRes } from '../../interfaces/companiesPlacesAndQueues/companiesPlacesAndQueues'
import { HttpCRUDRes, LocalCRUDRes } from '../../interfaces/http/http'

import mockData from '../../../assets/data/mock/compagniesPlacesAndQueues.json'


@Injectable( {
    providedIn: 'root'
} )
export class CompaniesPlacesQueuesService {

    private companiesPlacesAndQueuesLoaded: boolean = false
    private companiesPlacesAndQueues: CompaniesPlacesAndQueues = {}

    constructor (
        private httpService: HttpService,
        private configService: ConfigService
    ) {
    }

    public addLocalCompany( companyId: string, company: Company ): void {
        this.companiesPlacesAndQueues[ companyId ] = {
            company: company,
            places: {}
        }
    }

    public deleteLocalCompany( companyId: string ): void {
        if ( this.companiesPlacesAndQueues && this.companiesPlacesAndQueues[ companyId ] ) {
            delete this.companiesPlacesAndQueues[ companyId ]
        }
    }

    public updateLocalCompany( companyId: string, company: Company ): void {
        if ( this.companiesPlacesAndQueues && this.companiesPlacesAndQueues[ companyId ] ) {
            this.companiesPlacesAndQueues[ companyId ].company = company
        }
    }

    public addLocalPlace( companyId: string, placeId: string, place: Place ): void {
        if ( this.companiesPlacesAndQueues && this.companiesPlacesAndQueues[ companyId ] ) {
            if ( !this.companiesPlacesAndQueues[ companyId ].places ) {
                this.companiesPlacesAndQueues[ companyId ].places = {}
            }
            this.companiesPlacesAndQueues[ companyId ].places[ placeId ] = {
                place: place,
                queues: {}
            }
        }
    }

    public deleteLocalPlace( placeId: string ): void {
        const companyId = this.getPlaceById( placeId ).companyId
        if (
            this.companiesPlacesAndQueues
            && this.companiesPlacesAndQueues[ companyId ]
            && this.companiesPlacesAndQueues[ companyId ].places
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
        ) {
            delete this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
        }
    }

    public updateLocalPlace( placeId: string, place: Place ): void {
        const companyId = this.getPlaceById( placeId ).companyId
        if (
            this.companiesPlacesAndQueues
            && this.companiesPlacesAndQueues[ companyId ]
            && this.companiesPlacesAndQueues[ companyId ].places
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
        ) {
            this.companiesPlacesAndQueues[ companyId ].places[ placeId ] = { place: place, queues: this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues }
        }
    }

    public addLocalQueue( companyId: string, placeId: string, queueId: string, queue: Queue ): void {
        if (
            this.companiesPlacesAndQueues
            && this.companiesPlacesAndQueues[ companyId ]
            && this.companiesPlacesAndQueues[ companyId ].places
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
        ) {
            if ( !this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues) {
                this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues = {}
            }
            this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues[ queueId ] = queue
        }
    }

    public deleteLocalQueue( queueId: string ): void {
        const queue = this.getQueueById( queueId)
        if (!queue) return
        const placeId = queue.placeId
        const companyId = this.getPlaceById( placeId ).companyId
        if (
            this.companiesPlacesAndQueues
            && this.companiesPlacesAndQueues[ companyId ]
            && this.companiesPlacesAndQueues[ companyId ].places
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues[queueId]
        ) {
            delete this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues[queueId]
        }
    }

    public updateLocalQueue( queueId: string, queue: Queue ): void {
        const placeId = this.getQueueById( queueId ).placeId
        const companyId = this.getPlaceById( placeId ).companyId
        if (
            this.companiesPlacesAndQueues
            && this.companiesPlacesAndQueues[ companyId ]
            && this.companiesPlacesAndQueues[ companyId ].places
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ]
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues
            && this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues[queueId]
        ) {
            this.companiesPlacesAndQueues[ companyId ].places[ placeId ].queues[queueId] = queue
        }
    }

    public getCompaniesPlacesAndQueues (withoutDisabled?: boolean): Observable<CompaniesPlacesAndQueues> {
        if ( this.companiesPlacesAndQueuesLoaded ) {
            return of( _.cloneDeep( this.companiesPlacesAndQueues ) )
        }
        else {
            // return of(mockData as unknown).pipe(
            return this.httpService.get( this.configService.httpUrl.companies.getCompaniesPlacesAndQueues, null, null ).pipe(
                switchMap( ( res: CompaniesPlacesAndQueuesHttpRes ) => {
                    this.companiesPlacesAndQueuesLoaded = true
                    for ( const k in res.companyList ) {
                        if ( k in res.companyList ) {
                            this.companiesPlacesAndQueues[res.companyList[k].companyId] = {
                                company: res.companyList[k],
                                places: {}
                            }
                        }
                    }

                    for ( const k in res.placeFromCompany ) {
                        if ( k in res.placeFromCompany ) {
                            for ( const r in res.placeFromCompany[k] ) {
                                if ( r in res.placeFromCompany[k] ) {
                                    const placeId = res.placeFromCompany[k][r].placeId
                                    if ( this.companiesPlacesAndQueues[k] ) {
                                        this.companiesPlacesAndQueues[k].places[placeId] = {
                                            place: res.placeFromCompany[k][r],
                                            queues: {}
                                        }
                                    }
                                    // else {
                                    //     console.log('company does not exist part 1', { 
                                    //         companyId: k, 
                                    //         placeId: placeId 
                                    //     })
                                    // }
                                }
                            }
                        }
                    }

                    for ( const companyId in res.queueFromCompany ) {
                        if ( companyId in res.queueFromCompany ) {
                            for ( const placeId in res.queueFromCompany[companyId] ) {
                                if ( placeId in res.queueFromCompany[companyId] ) {
                                    for ( const t in res.queueFromCompany[companyId][placeId] ) {
                                        if ( t in res.queueFromCompany[companyId][placeId] ) {
                                            const queueId = res.queueFromCompany[companyId][placeId][t].queueId
                                            if ( this.companiesPlacesAndQueues[companyId] ) {
                                                if ( this.companiesPlacesAndQueues[companyId].places[placeId] ) {
                                                    if ( !withoutDisabled || (withoutDisabled && !res.queueFromCompany[companyId][placeId][t].disabled) ) {
                                                        this.companiesPlacesAndQueues[companyId].places[placeId].queues[queueId] = res.queueFromCompany[companyId][placeId][t]
                                                    }
                                                }
                                                // else {
                                                //     console.log('place does not exist part 2', {
                                                //         companyId,
                                                //         placeId,
                                                //         queueId
                                                //     })
                                                // }
                                            }
                                            // else {
                                            //     console.log('company does not exist part 2', {
                                            //         companyId,
                                            //         placeId,
                                            //         queueId
                                            //     })
                                            // }
                                        }
                                    }
                                    // console.log(companyId, placeId, this.companiesPlacesAndQueues[companyId].places[placeId])
                                    // if (this.companiesPlacesAndQueues[companyId].places[placeId] && Object.keys( this.companiesPlacesAndQueues[companyId].places[placeId].queues ).length === 0 ) {
                                    //     delete this.companiesPlacesAndQueues[companyId].places[placeId]
                                    // }
                                }
                            }

                            // if ( Object.keys( this.companiesPlacesAndQueues[companyId].places ).length === 0 ) {
                            //     delete this.companiesPlacesAndQueues[companyId]
                            // }
                        }
                    }

                    return of( _.cloneDeep( this.companiesPlacesAndQueues ) )
                } ),
                first()
            )
        }
    }

    public getCompanyById ( id: string ): Company | null {
        if ( id ) {
            for ( const companyId in this.companiesPlacesAndQueues ) {
                if ( companyId in this.companiesPlacesAndQueues ) {
                    if ( companyId === id ) {
                        return _.cloneDeep( this.companiesPlacesAndQueues[companyId].company )
                    }
                }
            }
        }
        return null
    }

    public getCompanyList ( excludeCompanyId?: string[] ): {companyId: string, langs: Langs}[] {
        const res = []
        for ( const companyId in this.companiesPlacesAndQueues ) {
            if ( !excludeCompanyId || excludeCompanyId.indexOf( companyId ) < 0 ) {
                res.push( {
                    companyId: companyId,
                    langs: this.companiesPlacesAndQueues[ companyId ].company.name
                })
            }
        }
        return res
    }

    public getPlaceById ( id: string ): Place | null {
        if ( id ) {
            for ( const companyId in this.companiesPlacesAndQueues ) {
                if ( companyId in this.companiesPlacesAndQueues ) {
                    for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                        if ( placeId in this.companiesPlacesAndQueues[companyId].places ) {
                            if ( placeId === id ) {
                                return _.cloneDeep( this.companiesPlacesAndQueues[companyId].places[placeId].place )
                            }
                        }
                    }
                }
            }
        }
        return null
    }

    public getPlaceList ( includeCompanyId: string[] | null, excludePlaceId?: string[] ): {placeId: string, langs: Langs}[] {
        const res = []
        for ( const companyId in this.companiesPlacesAndQueues ) {
            if ( !includeCompanyId || includeCompanyId.indexOf( companyId ) >= 0 ) {
                for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                    if ( !excludePlaceId || excludePlaceId.indexOf( placeId ) < 0 ) {
                        res.push( {
                            placeId: placeId,
                            langs: this.companiesPlacesAndQueues[companyId].places[placeId].place.name
                        } )
                    }
                }
            }
        }
        return res
    }

    public getTimezoneList (): string[] {
        const res = []
        for ( const companyId in this.companiesPlacesAndQueues ) {
            for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                const timezone = this.companiesPlacesAndQueues[companyId].places[placeId].place.timezone
                if ( timezone && res.indexOf( timezone ) < 0 ) {
                    res.push( this.companiesPlacesAndQueues[companyId].places[placeId].place.timezone )
                }
            }
        }
        return res
    }

    public getQueuesOfPlace ( placeId: string ): {[queueId: string]: Queue} | null {
        if ( placeId ) {
            for ( const companyId in this.companiesPlacesAndQueues ) {
                if ( companyId in this.companiesPlacesAndQueues ) {
                    if ( placeId in this.companiesPlacesAndQueues[companyId].places ) {
                        return _.cloneDeep( this.companiesPlacesAndQueues[companyId].places[placeId].queues )
                    }
                }
            }
        }
        return null
    }

    public getPlacesOfCompany ( companyId: string ): {[placeId: string]: Place} | null {
        if ( companyId ) {
            for ( const tmpCompanyId in this.companiesPlacesAndQueues ) {
                if ( tmpCompanyId in this.companiesPlacesAndQueues && tmpCompanyId === companyId ) {
                    let res = {}
                    for ( const placeId in this.companiesPlacesAndQueues[ tmpCompanyId ].places ) {
                        res[ placeId ] = this.companiesPlacesAndQueues[ tmpCompanyId ].places[ placeId ].place
                    }
                    return res
                }
            }
        }
        return null
    }

    public getQueueById ( id: string ): Queue | null {
        if ( id ) {
            for ( const companyId in this.companiesPlacesAndQueues ) {
                if ( companyId in this.companiesPlacesAndQueues ) {
                    for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                        if ( placeId in this.companiesPlacesAndQueues[companyId].places ) {
                            for ( const queueId in this.companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                                if ( queueId in this.companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                                    if ( queueId === id ) {
                                        return _.cloneDeep( this.companiesPlacesAndQueues[companyId].places[placeId].queues[queueId] )
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return null
    }

    public getQueueList ( includeCompanyId?: string[] | null, includePlaceId?: string[] | null, excludeQueueId?: string[] | null ): {queueId: string, langs: Langs}[] {
        const res = []
        for ( const companyId in this.companiesPlacesAndQueues ) {
            if ( !includeCompanyId || includeCompanyId.indexOf( companyId ) >= 0 ) {
                for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                    if ( !includePlaceId || includePlaceId.indexOf( placeId ) >= 0 ) {
                        for ( const queueId in this.companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                            if ( !excludeQueueId || excludeQueueId.indexOf( queueId ) < 0 ) {
                                res.push( {
                                    queueId: queueId,
                                    langs: this.companiesPlacesAndQueues[companyId].places[placeId].queues[queueId].name
                                } )
                            }
                        }
                    }
                }
            }
        }
        return res
    }

    public getQueueListWithFullName( includeCompanyId?: string[] | null, includePlaceId?: string[] | null, excludeQueueId?: string[] | null ): {queueId: string, langs: Langs}[] {
        const res = []
        for ( const companyId in this.companiesPlacesAndQueues ) {
            if ( !includeCompanyId || includeCompanyId.indexOf( companyId ) >= 0 ) {
                for ( const placeId in this.companiesPlacesAndQueues[companyId].places ) {
                    if ( !includePlaceId || includePlaceId.indexOf( placeId ) >= 0 ) {
                        for ( const queueId in this.companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                            if ( !excludeQueueId || excludeQueueId.indexOf( queueId ) < 0 ) {
                                let completeLangs = {}
                                for (const lang in this.companiesPlacesAndQueues[companyId].places[placeId].queues[queueId].name) {
                                    completeLangs[lang] = this.companiesPlacesAndQueues[companyId].places[placeId].place.name[lang] + ' - ' + this.companiesPlacesAndQueues[companyId].places[placeId].queues[queueId].name[lang]

                                }
                                res.push( {
                                    queueId: queueId,
                                    langs: completeLangs
                                } )
                            }
                        }
                    }
                }
            }
        }
        return res
    }

    public searchById ( id: string ): Observable<{ found: boolean, type: string, res: { company: Company | null, place: Place | null, queue: Queue | null } }> {
        if ( id ) {
            return this.getCompaniesPlacesAndQueues().pipe(
                switchMap( ( companiesPlacesAndQueues: CompaniesPlacesAndQueues ) => {

                    for ( const companyId in companiesPlacesAndQueues ) {
                        if ( companyId in companiesPlacesAndQueues ) {

                            if ( companyId === id ) {
                                return of( {
                                    found: true,
                                    type: 'company',
                                    res: {
                                        company: _.cloneDeep( companiesPlacesAndQueues[companyId].company ),
                                        place: null,
                                        queue: null
                                    }
                                } )
                            }

                            for ( const placeId in companiesPlacesAndQueues[companyId].places ) {
                                if ( placeId in companiesPlacesAndQueues[companyId].places ) {

                                    if ( placeId === id ) {
                                        return of( {
                                            found: true,
                                            type: 'place',
                                            res: {
                                                company: _.cloneDeep( companiesPlacesAndQueues[companyId].company ),
                                                place: _.cloneDeep( companiesPlacesAndQueues[companyId].places[placeId].place ),
                                                queue: null
                                            }
                                        } )
                                    }

                                    for ( const queueId in companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                                        if ( queueId in companiesPlacesAndQueues[companyId].places[placeId].queues ) {
                                            if ( queueId === id ) {
                                                return of( {
                                                    found: true,
                                                    type: 'queue',
                                                    res: {
                                                        company: _.cloneDeep( companiesPlacesAndQueues[companyId].company ),
                                                        place: _.cloneDeep( companiesPlacesAndQueues[companyId].places[placeId].place ),
                                                        queue: _.cloneDeep( companiesPlacesAndQueues[companyId].places[placeId].queues[queueId] )
                                                    }
                                                } )
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    return of( { found: false, type: 'company', res: { company: null, place: null, queue: null } } )
                } ),
                first()
            )
        }
        else {
            return of( { found: false, type: 'company', res: { company: null, place: null, queue: null } } )
        }
    }

    public createQueue( params: Partial<Queue>[] ): Observable<LocalCRUDRes> {
        if ( Object.keys( params ).length > 0 ) {
            return this.httpService.post(
                this.configService.httpUrl.queues.createQueues, { queues: params }, null, null
            ).pipe(
                switchMap( ( httpRes: HttpCRUDRes ) => {
                    for ( const queueId in httpRes.objectSuccess ) {
                        if ( queueId in httpRes.objectSuccess ) {
                            const companyid = httpRes.objectSuccess[ queueId ].companyId
                            const placeId = httpRes.objectSuccess[ queueId ].placeId
                            this.companiesPlacesAndQueues[companyid].places[placeId].queues[ queueId ] = httpRes.objectSuccess[ queueId ]
                        }
                    }

                    if ( httpRes.error === 0 && httpRes.success > 0 ) {
                        return of( { success: true, res: httpRes.objectSuccess } )
                    } else {
                        return of( { success: false, nbError: httpRes.error, totalElem: (httpRes.error + httpRes.success) } )
                    }
                })
            )
        } else {
            return of( { success: true } )
        }
    }

    public reset() {
        this.companiesPlacesAndQueuesLoaded = false
        this.companiesPlacesAndQueues = {}
    }

    public getCompanyIdOfPlace( placeId: string ): string | null {
        for ( const companyId in this.companiesPlacesAndQueues ) {
            if ( companyId in this.companiesPlacesAndQueues ) {
                if ( placeId in this.companiesPlacesAndQueues[companyId].places ) {
                    return companyId
                }
            }
        }
        return null
    }   
}
