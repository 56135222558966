import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import { of } from 'rxjs'
import { UserService } from '../services/user.service'
import { NavigationService } from '../services/utils/navigation.service'
import { WindowService } from '../services/utils/window.service'

@Injectable()
export class AuthGuard  {

    constructor (
        private userService: UserService,
        private navigationService: NavigationService,
        private windowService: WindowService,
        private router: Router
    ) {
    }

    canActivate ( route: ActivatedRouteSnapshot ) {
        // return true
        let roles = route.data.roles as Array<string>;
        return this.userService.isAuthenticated()
            .pipe(
                switchMap( ( authenticated: boolean ) => {
                    if ( authenticated ) {
                        return of( true )
                    }
                    else {
                        const currentUrl = this.windowService.getUrl().replace( /.+\/#\//, '/' )
                        this.navigationService.treatNavigationUrl( currentUrl )
                        this.router.navigate( ['/auth/before-logout'] )
                        return of( false )
                    }
                } )
            )
    }
}
